import React, { useContext, useEffect, useState } from 'react';
import { MicrocopyContext } from '../../context/microcopy.context';
import SearchField from '../ui/filterSearch/components/searchField';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import * as styles from './styles.module.scss';
import StoryItem from './components/storyItem';
import WinnerItem from './components/winnerItem';
import { getCopy } from '../../helpers/copy';
import Container from '../../modules/container';
import { AWARDS, AWARD_IDS, getCountryName } from '../../utils/constants';
import { useLocale } from '../../context/locale.context';
import CustomScrollable from '../ui/customScrollable';
import { combine } from '../../helpers/styles';
import Text from '../ui/text';
import Link from '../ui/link';
import Button from '../ui/button';
import Spinner from './components/spinner';
import { useSearchResult } from '../../hooks/useSearchResult';
import { navigate } from 'gatsby';
import {
  getJurors,
  getProfiles,
  getStories,
  getWinnersCount,
} from '../../helpers/search';
import ResultsList from '../ui/resultsList';
import { useWinnerFilters } from '../../hooks/useWinnerFilters';
import Radiobutton from '../ui/radiobutton';

const WINNER_LIMIT = 21;
const PROFILES_LIMIT = 4;
const JURORS_LIMIT = 4;
const STORIES_LIMIT = 3;

const Results = () => {
  const microcopy = useContext(MicrocopyContext);
  const { locale } = useLocale();

  const [query, _] = useQueryParams({
    tagId: StringParam,
    awardId: ArrayParam,
    regionId: ArrayParam,
    countryId: ArrayParam,
    disciplineId: ArrayParam,
    categoryId: ArrayParam,
    yearId: ArrayParam,
    sort: StringParam,
    find: StringParam,
    inspiration: BooleanParam,
    profile: StringParam,
    seed: StringParam,
    page: NumberParam,
  });

  const [winnerCount, setWinnerCount] = useState({
    designAwardCount: 0,
    designTalentAwardCount: 0,
    socialProjectsCount: 0,
    totalAwardCount: 0,
  });
  const [profiles, setProfiles] = useSearchResult();
  const [jurors, setJurors] = useSearchResult();
  const [stories, setStories] = useSearchResult();
  const [showAllStories, setShowAllStories] = useState(false);
  const [showAllJurors, setShowAllJurors] = useState(false);
  const [showAllProfiles, setShowAllProfiles] = useState(false);
  const [windowLocation, setWindowLocation] = useState(null);
  const [selectedTabAwardId, setSelectedTabAwardId] = useState(AWARD_IDS.IFDA);

  useEffect(() => {
    setWindowLocation(location);
    switch (parseInt(query.awardId?.[0] ?? 0)) {
      case AWARD_IDS.IFDA:
      case AWARD_IDS.IFDA_GOLD:
        setSelectedTabAwardId(AWARD_IDS.IFDA);
        break;
      case AWARD_IDS.IFSIP:
      case AWARD_IDS.IF_SIP_SUPPORTED:
        setSelectedTabAwardId(AWARD_IDS.IFSIP);
        break;
      case AWARD_IDS.IFDSA:
      case AWARD_IDS.IFDSA_BEST:
        setSelectedTabAwardId(AWARD_IDS.IFDSA);
        break;
      default:
        setSelectedTabAwardId(AWARD_IDS.IFDA);
    }
  }, [query]);

  const queryFilters = {
    awards: query.awardId ? query.awardId.map(Number) : [2],
    disciplines: query.disciplineId ? query.disciplineId.map(Number) : [],
    categories: query.categoryId ? query.categoryId.map(Number) : [],
    region: query.regionId ? query.regionId.map(String) : [],
    countries: query.countryId ? query.countryId.map(String) : [],
    time: query.yearId ? query.yearId.map(Number) : [4],
    inspiration: query.inspiration,
    tag: query.tagId,
    sort: query.sort ? query.sort : 'desc',
    range: query.range ? query.range : 4,
    find: query.find,
    page: query.page - 1 || 0,
    profile: query.profile,
    seed: query.seed || '',
  };

  useEffect(() => {
    getWinnersCount({ queryFilters, cb: setWinnerCount });
    getProfiles({ term: query.find, cb: setProfiles });
    getJurors({ term: query.find, cb: setJurors });
    getStories({ term: query.find, cb: setStories });
  }, [query]);

  const getLink = (awardId) => {
    const searchParams = new URLSearchParams(windowLocation?.search);
    searchParams.set('awardId', awardId);
    searchParams.delete('page');

    return `?${searchParams.toString()}`;
  };

  const getResultType = () => {
    switch (queryFilters.awards[0]) {
      case AWARD_IDS.IFDA:
      case AWARD_IDS.IFDA_GOLD:
        return '';
      case AWARD_IDS.IFSIP:
      case AWARD_IDS.IF_SIP_SUPPORTED:
        return 'sip';
      case AWARD_IDS.IFDSA:
      case AWARD_IDS.IFDSA_BEST:
        return 'talent';
      default:
        return '';
    }
  };

  const getApiParamType = () => {
    switch (queryFilters.awards[0]) {
      case AWARD_IDS.IFDA:
      case AWARD_IDS.IFDA_GOLD:
        return 'project';
      case AWARD_IDS.IFSIP:
      case AWARD_IDS.IF_SIP_SUPPORTED:
        return 'sip';
      case AWARD_IDS.IFDSA:
      case AWARD_IDS.IFDSA_BEST:
        return 'student';
      default:
        return 'project';
    }
  };

  const noResults =
    winnerCount.totalAwardCount === 0 &&
    !profiles.count &&
    !jurors.count &&
    !stories.count &&
    stories.recommended === null;

  const AwardLink = ({ awardId, count, children }) =>
    count ? (
      <Link
        link={{
          linkToPage: '/search',
          queryParameter: getLink(awardId),
          anchor: 'resultList',
        }}
      >
        {children}
      </Link>
    ) : (
      children
    );

  return (
    <>
      <div className={styles.search}>
        <SearchField
          initSearchTerm={query.find}
          onSubmit={(val) => navigate(`?find=${val}`, { replace: true })}
          onReset={() => navigate(`?find)`, { replace: true })}
          placeholder={getCopy('search.input.placeholder', microcopy)}
          type="Global"
        />
      </div>
      <Container className={styles.results}>
        {noResults ? (
          <div className={combine(styles.section, styles.noResults)}>
            <Text as="h3" type="h3">
              {getCopy('results.generic.noResults', microcopy)}
            </Text>
          </div>
        ) : (
          <>
            {/* RECOMMENDED */}
            {stories.recommended !== null && (
              <div className={styles.section}>
                <div className={styles.sectionTop}>
                  <h2 className={styles.sectionTitle}>
                    {getCopy('search.recommended', microcopy)}
                  </h2>
                </div>
                {stories.loading ? (
                  <Spinner />
                ) : (
                  <StoryItem
                    {...stories.recommended}
                    image={{ url: stories.recommended?.image, loading: 'lazy' }}
                    asRecommended
                  />
                )}
              </div>
            )}
          </>
        )}
      </Container>
      <div className={styles.main}>
        <Container className={styles.results}>
          <>
            {/* WINNERS */}
            <div className={styles.section}>
              <div className={styles.sectionTop}>
                <h2 className={styles.sectionTitle}>
                  {getCopy('search.winnersearchresultstitle', microcopy)}
                  <span className={styles.count}>
                    {' '}
                    ({winnerCount.totalAwardCount})
                  </span>
                </h2>
              </div>
              <div className={styles.awardsRadios}>
                <AwardLink
                  count={winnerCount.designAwardCount}
                  awardId={AWARD_IDS.IFDA}
                >
                  <Radiobutton
                    label={`${AWARDS.IFDA} (${winnerCount.designAwardCount})`}
                    defaultChecked={selectedTabAwardId === AWARD_IDS.IFDA}
                    className={styles.radioButton}
                    disabled={winnerCount.designAwardCount === 0}
                    isLink
                  />
                </AwardLink>
                <AwardLink
                  count={winnerCount.socialProjectsCount}
                  awardId={AWARD_IDS.IFSIP}
                >
                  <Radiobutton
                    label={`${AWARDS.IFSIP} (${winnerCount.socialProjectsCount})`}
                    defaultChecked={selectedTabAwardId === AWARD_IDS.IFSIP}
                    className={styles.radioButton}
                    disabled={winnerCount.socialProjectsCount === 0}
                    isLink
                  />
                </AwardLink>
                <AwardLink
                  count={winnerCount.designTalentAwardCount}
                  awardId={AWARD_IDS.IFDSA}
                >
                  <Radiobutton
                    label={`${AWARDS.IFDSA} (${winnerCount.designTalentAwardCount})`}
                    defaultChecked={selectedTabAwardId === AWARD_IDS.IFDSA}
                    className={styles.radioButton}
                    disabled={winnerCount.designTalentAwardCount === 0}
                    isLink
                  />
                </AwardLink>
              </div>
              <CustomScrollable className={styles.awardsScrollable}>
                <Link
                  link={{
                    linkToPage: '/search',
                    queryParameter: getLink(AWARD_IDS.IFDA),
                    anchor: 'resultList',
                  }}
                  as="span"
                  role="button"
                  className={combine(
                    styles.awardTab,
                    selectedTabAwardId === AWARD_IDS.IFDA && styles.selected
                  )}
                >
                  {`${AWARDS.IFDA} (${winnerCount.designAwardCount})`}
                </Link>
                <Link
                  link={{
                    linkToPage: '/search',
                    queryParameter: getLink(AWARD_IDS.IFSIP),
                    anchor: 'resultList',
                  }}
                  as="span"
                  role="button"
                  className={combine(
                    styles.awardTab,
                    winnerCount.socialProjectsCount === 0 && styles.disabled,
                    selectedTabAwardId === AWARD_IDS.IFSIP && styles.selected
                  )}
                >
                  {`${AWARDS.IFSIP} (${winnerCount.socialProjectsCount})`}
                </Link>
                <Link
                  link={{
                    linkToPage: '/search',
                    queryParameter: getLink(AWARD_IDS.IFDSA),
                    anchor: 'resultList',
                  }}
                  as="span"
                  role="button"
                  className={combine(
                    styles.awardTab,
                    winnerCount.designTalentAwardCount === 0 && styles.disabled,
                    selectedTabAwardId === AWARD_IDS.IFDSA && styles.selected
                  )}
                >
                  {`${AWARDS.IFDSA} (${winnerCount.designTalentAwardCount})`}
                </Link>
              </CustomScrollable>

              <>
                {/* <div style={{ '--cols': 3 }} className={styles.winners}>
                        {visibleWinners.items.map((winner) => (
                          <WinnerItem
                            date={winner.timestamp}
                            key={winner.slug}
                            slug={winner.slug}
                            title={winner.name}
                            subtitle={winner.designation}
                            additionalInfo={winner.clients?.[0]}
                            image={{
                              image: { url: winner.primaryMediaThumbnail },
                            }}
                          />
                        ))}
                      </div> */}
                <ResultsList
                  queryFilters={queryFilters}
                  filtersHook={useWinnerFilters}
                  filtersApiParam={getApiParamType()}
                  noResultsLabelKey="results.projects.noResults"
                  teaserType="design"
                  type={getResultType()}
                  itemRenderer={(winner) => (
                    <WinnerItem
                      date={winner.awardYear}
                      key={winner.slug}
                      slug={winner.slug}
                      title={winner.name}
                      subtitle={winner.designation}
                      additionalInfo={winner.clients?.[0]}
                      image={{
                        image: { url: winner.primaryMediaThumbnail },
                      }}
                    />
                  )}
                  isInSearchPage
                  hideFilterOnLoad
                />
              </>
            </div>
            {/* COMPANIES */}
            {profiles.count > 0 && (
              <div className={styles.section}>
                <div className={styles.sectionTop}>
                  <h2 className={styles.sectionTitle}>
                    {getCopy('search.profilesearchresultstitle', microcopy)}
                    <span className={styles.count}> ({profiles.count})</span>
                  </h2>
                </div>
                {profiles.loading ? (
                  <Spinner />
                ) : (
                  <div className={styles.winners}>
                    {profiles.items
                      .slice(0, showAllProfiles ? undefined : PROFILES_LIMIT)
                      .map((profile) => (
                        <WinnerItem
                          key={profile.slug}
                          slug={profile.slug}
                          title={profile.name}
                          subtitle={getCountryName(locale, profile.country)}
                          image={{ image: { url: profile.image } }}
                          type="profile"
                        />
                      ))}
                  </div>
                )}
                {profiles.count > PROFILES_LIMIT && !showAllProfiles && (
                  <div className={styles.showMoreContainer}>
                    <Button
                      onClick={() => setShowAllProfiles(true)}
                      text={getCopy('btn.showMore', microcopy)}
                    />
                  </div>
                )}
              </div>
            )}
            {/* JURORS */}
            {jurors.count > 0 && (
              <div className={styles.section}>
                <div className={styles.sectionTop}>
                  <h2 className={styles.sectionTitle}>
                    {getCopy('search.jurorsearchresultstitle', microcopy)}
                    <span className={styles.count}> ({jurors.count})</span>
                  </h2>
                </div>
                {jurors.loading ? (
                  <Spinner />
                ) : (
                  <div className={combine(styles.winners, styles.horizontal)}>
                    {jurors.items
                      .slice(0, showAllJurors ? undefined : JURORS_LIMIT)
                      .map((juror) => (
                        <WinnerItem
                          key={juror.slug}
                          slug={juror.slug}
                          title={juror.name}
                          subtitle={getCountryName(locale, juror.country)}
                          image={{ image: { url: juror.image } }}
                          type="juror"
                          asRow
                        />
                      ))}
                  </div>
                )}
                {jurors.count > JURORS_LIMIT && !showAllJurors && (
                  <div className={styles.showMoreContainer}>
                    <Button
                      onClick={() => setShowAllJurors(true)}
                      text={getCopy('btn.showMore', microcopy)}
                    />
                  </div>
                )}
              </div>
            )}
            {/* STORIES */}
            {stories.count > 0 && (
              <div className={styles.section}>
                <div className={styles.sectionTop}>
                  <h2 className={styles.sectionTitle}>
                    {getCopy('search.storysearchresultstitle', microcopy)}
                    <span className={styles.count}> ({stories.count})</span>
                  </h2>
                </div>
                {stories.loading ? (
                  <Spinner />
                ) : (
                  <div style={{ '--cols': 3 }} className={styles.winners}>
                    {stories.items
                      .slice(0, showAllStories ? undefined : STORIES_LIMIT)
                      .map((story) => (
                        <StoryItem
                          {...story}
                          image={{ url: story.image, loading: 'lazy' }}
                          key={story.slug}
                        />
                      ))}
                  </div>
                )}
                {stories.count > STORIES_LIMIT && !showAllStories && (
                  <div className={styles.showMoreContainer}>
                    <Button
                      onClick={() => setShowAllStories(true)}
                      text={getCopy('btn.showMore', microcopy)}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        </Container>
      </div>
    </>
  );
};

export default Results;
