import { useEffect, useState } from 'react';
import { getAPIRoot } from '../helpers/fetch';
import {
  getSIPYearFilters,
  getYearFilters,
  getOpenedFilters,
} from '../helpers/filters';
import { AWARD_IDS, AWARDS } from '../utils/constants';

const CURRENT_YEAR = 2024;

const withCustomAwardsAndSorted = (awards, type) => {
  const iFDAward = awards.find((award) => award.id === AWARD_IDS.IFDA);
  const iFDATalentAward = awards.find((award) => award.id === AWARD_IDS.IFDSA);
  const iFSIPAward = awards.find((award) => award.id === AWARD_IDS.IFSIP);

  return [
    ...(type === 'project'
      ? [
          iFDAward,
          {
            id: AWARD_IDS.IFDA_GOLD,
            name: AWARDS.IFDA_GOLD,
            year: iFDAward.year,
          },
        ]
      : []),
    ...(type === 'student'
      ? [
          {
            ...iFDATalentAward,
            name: AWARDS.IFDSA,
            year: iFDATalentAward.year,
          },
          {
            id: AWARD_IDS.IFDSA_BEST,
            name: AWARDS.IFDSA_BEST,
            year: iFDATalentAward.year,
          },
        ]
      : []),
    ...(!type || type === 'sip'
      ? [
          {
            ...iFSIPAward,
            hide: true,
            name: AWARDS.IFSIP,
            locked: true,
          },
        ]
      : []),
  ];
};

// adapt current filters to what backend expects
const prepareFiltersForAPI = (filters, apiFilters, seed, count) => {
  const filtersForAPI = {
    // only one award can be selected
    award:
      filters?.awards?.map((filter) => {
        // map fake award ids (sub awards) to their respective award
        if (filter === AWARD_IDS.IFDA_GOLD) {
          return AWARD_IDS.IFDA;
        } else if (filter === AWARD_IDS.IFDSA_BEST) {
          return AWARD_IDS.IFDSA;
        } else if (filter === AWARD_IDS.IF_SIP_SUPPORTED) {
          return AWARD_IDS.IFSIP;
        }

        return filter;
      })[0] ?? AWARD_IDS.IFDA,
    // if there are regions selected but no countries, grab all countries from those regions
    countries:
      (filters?.region?.length && filters?.countries?.length === 0
        ? apiFilters?.countries
            .filter((country) => filters?.region?.includes(country.region))
            .map((country) => country.id)
        : filters?.countries) || [],
    year: CURRENT_YEAR,
    range: filters?.time[0],
    seed: filters?.seed || seed,
    count,
    find: filters?.find || '',
    disciplines: filters?.disciplines || [],
    categories: [AWARD_IDS.IFDA_GOLD, AWARD_IDS.IFDA].some((filter) =>
      filters?.awards?.includes(filter)
    )
      ? filters?.categories || []
      : [],
    onlySpecialAward: [
      AWARD_IDS.IFDA_GOLD,
      AWARD_IDS.IFDSA_BEST,
      AWARD_IDS.IF_SIP_SUPPORTED,
    ].some((filter) => filters?.awards?.includes(filter)),
    profileId: Number(filters?.profile?.split('|id=')?.pop()) || 0,
  };

  return JSON.stringify(filtersForAPI);
};

export const useWinnerFilters = (
  filters,
  items = [],
  type = null,
  isInSearch
) => {
  const [totalCount, setTotalCount] = useState(null);
  const [apiFilters, setApiFilters] = useState({});
  const [visibleFilters, setVisibleFilters] = useState(null);
  const [page, setPage] = useState(filters.page || 0);
  const [tempFilters, setTempFilters] = useState(null); // used when deferred
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noMoreResults, setNoMoreResults] = useState(true);
  const [payload, setPayload] = useState('');
  const [seed, setSeed] = useState(filters.seed || '');

  // get filters from backend when component loads
  useEffect(() => {
    getAPIFilters();
  }, []);

  // initialize visible filters after receiving possible filters from backend
  // change them if filters changed
  useEffect(() => {
    if (Object.values(apiFilters).length) {
      const newVisibleFilters = {
        sort: [
          {
            id: 'desc',
            name: 'Newest',
          },
          {
            id: 'asc',
            name: 'Oldest',
          },
          {
            id: 'random',
            name: 'Random',
          },
        ],
        ...apiFilters,
        categories: getCategories(),
        disciplines: getDisciplines(),
        countries: getCountries(),
      };
      setVisibleFilters(newVisibleFilters);
    }
  }, [apiFilters, filters]);

  // update results once we have calculated what filters should be visible
  useEffect(() => {
    if (visibleFilters && filters) {
      updateResults();
    }
  }, [visibleFilters]);

  // update results on page change (only used with infinite scrolling)
  useEffect(() => {
    if (page > 0 && !isInSearch) {
      updateResults();
    }
  }, [page]);

  const increasePage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // returns categories that should be visible
  // if not gold award nor iFDA, show sipTalentCategories
  const getCategories = () => {
    return apiFilters?.categories ?? [];
  };

  // returns disciplines that should be visible
  // disciplines should only be shown when iFDA or gold award selected
  const getDisciplines = () => {
    return apiFilters?.disciplines ?? [];
  };

  // returns country filters that should be visible
  const getCountries = () => {
    // if more than 1 region selected, remove countries
    if (filters?.region?.length > 1) {
      return [];
    }
    // if 1 region selected, only show countries from that region
    if (filters?.region?.length === 1) {
      const regionName = apiFilters?.region?.find(
        (region) => region.id === filters?.region[0]
      ).id;
      return apiFilters?.countries?.filter(
        (country) => country.region === regionName
      );
    }
    // if no regions selected, show all countries
    if (!filters?.region?.length) {
      return apiFilters?.countries;
    }
  };

  useEffect(() => {
    sessionStorage?.setItem(
      `${type}QueryParams`,
      new URLSearchParams(location?.search).toString()
    );
    if (isInSearch) {
      sessionStorage?.setItem('returnToSearchPage', true);
    } else {
      sessionStorage?.removeItem('returnToSearchPage');
    }
  }, []);

  useEffect(() => {
    const payloadObj = JSON.parse(payload || '{}');
    sessionStorage?.setItem(
      `${type}Payload`,
      JSON.stringify({
        ...payloadObj,
        sort: filters.sort,
        page,
      })
    );
  }, [payload]);

  useEffect(() => {
    sessionStorage?.setItem(`${type}Count`, totalCount);
  }, [totalCount]);

  // const getInspirationResults = async () => {
  //   let apiRoot = await getAPIRoot();
  //   const url = `${apiRoot}${process.env.GATSBY_API_ENTRIES_RANDOM}/15`;
  //   const options = {
  //     method: 'GET',
  //     redirect: 'follow',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   };

  //   const response = await fetch(url, options);
  //   const json = await response.json();

  //   return json['items'];
  // };

  const getFiltersThatShouldApply = () => {
    // get only filters that are visible
    return Object.fromEntries(
      Object.entries(filters).map(([key, appliedFilter]) => {
        if (Array.isArray(appliedFilter)) {
          return [
            key,
            appliedFilter.filter((appliedFilterItem) =>
              visibleFilters?.[key].find(
                (visibleFilter) => visibleFilter.id === appliedFilterItem
              )
            ),
          ];
        } else {
          return [key, appliedFilter];
        }
      })
    );
  };

  const getFilteredResultsJsonResponse = async () => {
    let apiRoot = await getAPIRoot();
    const itemsPerPage = isInSearch ? 28 : 18;

    const filtersThatShouldApply = getFiltersThatShouldApply();

    const _payload = prepareFiltersForAPI(
      filtersThatShouldApply,
      apiFilters,
      seed,
      itemsPerPage
    );
    setPayload(_payload);
    const url = `${apiRoot}${
      process.env.GATSBY_API_SEARCH_WINNER
    }/${page}/${itemsPerPage}?order=${filters.sort || 'desc'}`;
    const options = {
      method: 'POST',
      redirect: 'follow',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: _payload,
    };

    const response = await fetch(url, options);
    return response.json();
  };

  // const getAPICategories = async (localApiFilters = apiFilters) => {
  //   let apiRoot = await getAPIRoot();

  //   const url = apiRoot + process.env.GATSBY_API_ENTRIES_CATEGORIES;

  //   const fullPayload = prepareFiltersForAPI(filters, localApiFilters);
  //   // we only need countries, disciplines, find and years to fetch categories
  //   const { countries, disciplines, find, years } = JSON.parse(fullPayload);
  //   const payload = JSON.stringify({ countries, disciplines, find, years });

  //   const options = {
  //     method: 'POST',
  //     redirect: 'follow',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: payload,
  //   };

  //   const response = await fetch(url, options);
  //   const cats = (await response.json()).sort(sortFiltersAlphabetically);
  //   return cats;
  // };

  // get filters from backend, rename them and set state
  const getAPIFilters = async () => {
    let apiRoot = await getAPIRoot();
    const url = apiRoot + process.env.GATSBY_API_SEARCH_WINNER_FILTER;

    const response = await fetch(url, {
      method: 'POST',
    });
    const _apiFilters = await response.json();

    const tempApiFilters = {
      awards: withCustomAwardsAndSorted(_apiFilters['awards'], type),
      time:
        type === 'sip'
          ? getSIPYearFilters({ allTime: true, showCurrentYear: true })
          : getYearFilters({
              allTime: true,
              showCurrentYear: true,
              type,
            }),
      ...(type == 'project' && { disciplines: _apiFilters['disciplines'] }),
      ...(type == 'project' && { categories: _apiFilters['categories'] }),
      region: _apiFilters['regions'],
      countries: _apiFilters['countries'],
    };

    // tempApiFilters.categories = await getAPICategories(tempApiFilters);

    setApiFilters(tempApiFilters);
  };

  const updateResults = async () => {
    if (filters?.inspiration) {
      setResults(await getInspirationResults());
      setNoMoreResults(true);
    }

    const json = await getFilteredResultsJsonResponse();

    if (!json.items.length) {
      setNoMoreResults(true);
    }

    setTotalCount(json.count);
    setSeed(json.seed);

    if (page > 0) {
      const _results = results.concat(json.items);
      if (_results.length === json.count) {
        setNoMoreResults(true);
      }
      setResults(_results);
    } else {
      setResults(json.items);
      setNoMoreResults(json.items.length === json.count);
    }

    setLoading(false);
  };

  return {
    visibleFilters,
    filterOrderByKey:
      type === 'sip'
        ? [
            'sort',
            'awards',
            'time',
            'disciplines',
            'categories',
            'region',
            'countries',
          ]
        : [
            'sort',
            'awards',
            'time',
            'region',
            'countries',
            'disciplines',
            'categories',
          ],
    increasePage,
    results,
    loading,
    noMoreResults,
    totalCount,
    openedFilters: getOpenedFilters(filters, ['sort', 'awards', 'time']),
    radioFiltersKeys: ['sip'].includes(type)
      ? ['sort', 'time']
      : ['sort', 'awards', 'time'],
    seed,
  };
};
